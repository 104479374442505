 
// ----------------------------------------------------------------------

import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AppConversionRates } from "../sections/@dashboard/app";

export default function DashboardAppPage({posts, transactions, users, businesses}) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const [regDay, setRegDay] = useState([])
  const [regBusinessDay, setBusinessRegDay] = useState([])
  const [regPostsDay, setPostsRegDay] = useState([])
  const [regTransactionDay, setTransactionRegDay] = useState([])

useEffect(() => {
  getPerDayReg()
  getBusinessPerDayReg()
  getPostsPerDayReg()
  getTransactionsPerDayReg()
}, [])


function getPerDayReg() {
  const currentMonth = new Date().getMonth()+1;
  const groupedUsers = users?.reduce((acc, user) => {
    const userMonth = new Date(user.createdAt).getMonth()+1;
    if (userMonth === currentMonth) {
      acc[userMonth] = [...(acc[userMonth] || []), user];
    }
    return acc;
  }, {});

  const _data = Object.entries(groupedUsers || {})
    .filter(([key]) => key !== "null")
    .map(([key, value]) => ({ label: key, value: value.length }))
    .sort((a, b) => new Date(a.label) - new Date(b.label));

  setRegDay(_data);
}

function getBusinessPerDayReg() {
  const currentMonth = new Date().getMonth()+1;
 
  const groupedUsers = businesses?.reduce((acc, user) => {
    const userMonth = new Date(user.createdAt).getMonth()+1;
 
    if (userMonth === currentMonth) {
      acc[userMonth] = [...(acc[userMonth] || []), user];
    }
    return acc;
  }, {});

  const _data = Object.entries(groupedUsers || {})
    .filter(([key]) => key !== "null")
    .map(([key, value]) => ({ label: key, value: value.length }))
    .sort((a, b) => new Date(a.label) - new Date(b.label));

    setBusinessRegDay(_data);
}

function getPostsPerDayReg() {
  const currentMonth = new Date().getDate();
  console.log(currentMonth)
  const groupedUsers = posts?.reduce((acc, user) => {
    const userMonth = new Date(user.createdAt).getDate()
    console.log(userMonth, currentMonth)
    if (userMonth === currentMonth) {
      acc[userMonth] = [...(acc[userMonth] || []), user];
    }
    return acc;
  }, {});

  const _data = Object.entries(groupedUsers || {})
    .filter(([key]) => key !== "null")
    .map(([key, value]) => ({ label: key, value: value.length }))
    .sort((a, b) => new Date(a.label) - new Date(b.label));

    setPostsRegDay(_data);
}

function getTransactionsPerDayReg() {
  const currentMonth = new Date().getMonth()+1;
  console.log(currentMonth)
  const groupedUsers = posts?.reduce((acc, user) => {
    const userMonth = new Date(user.createdAt).getMonth()+1;
    console.log(userMonth, currentMonth)
    if (userMonth === currentMonth) {
      acc[userMonth] = [...(acc[userMonth] || []), user];
    }
    return acc;
  }, {});
  console.log(groupedUsers)
  const _data = Object.entries(groupedUsers || {})
    .filter(([key]) => key !== "null")
    .map(([key, value]) => ({ label: key, value: value.length }))
    .sort((a, b) => new Date(a.label) - new Date(b.label));

    setTransactionRegDay(_data);
}

  
  return (
   <>
     <Grid container spacing={2} >  
        <Grid item xs={12} sm={12} md={12}>
          <AppConversionRates   title="Registrations By Month"
              subheader={ months[new Date(Date.now()).getMonth()]}
              chartData={regDay && regDay.map((asd) => ({ label: asd.label, value: asd.value }))}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppConversionRates   title="Business Registrations By Month"
              subheader={ months[new Date(Date.now()).getMonth()]}
              chartData={regBusinessDay && regBusinessDay.map((asd) => ({ label: asd.label, value: asd.value }))}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppConversionRates   title="Posts made By Month"
              subheader={ months[new Date(Date.now()).getMonth()]}
              chartData={regPostsDay && regPostsDay.map((asd) => ({ label: asd.label, value: asd.value }))}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppConversionRates   title="Transactions made By Month"
              subheader={ months[new Date(Date.now()).getMonth()]}
              chartData={regTransactionDay && regTransactionDay.map((asd) => ({ label: asd.label, value: asd.value }))}/>
        </Grid>
        </Grid>
    </>
  );
}
