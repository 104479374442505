import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Table, TableBody, TableCell, TableRow, Button } from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
 
import UserName from '../../../_mock/UserName';
import { db } from '../../../firebase';

// ----------------------------------------------------------------------

export default function Blogs({data}) {
 
  async function  deleteAlbum(id){
    await deleteDoc(doc(db, 'posts', id)).then(()=>{
     alert("item removed.")
    })
}

  return (
   <>
      <Helmet>
        <title> Blogs</title>
      </Helmet>

      <Container>
 
 <Table>
    <TableBody>

    {data?.map((d, index)=>(
        <TableRow key={index}>
        <TableCell><UserName id={d.userId} /></TableCell>
        <TableCell><Typography>{d.post}</Typography></TableCell> 
        <TableCell>{new Date(d.createdAt).toLocaleDateString()}</TableCell>
        <TableCell><Button color="error" onClick={()=>deleteAlbum(d.id)}>Remove</Button></TableCell>
    </TableRow>
    ))}
    </TableBody>
 </Table>
      </Container>
    </>
  );
}
