/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
// @mui
import { Button,  Container, Grid, MenuItem, Stack, TextField, Typography, styled } from '@mui/material';
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import { useNavigate } from 'react-router-dom';
import { addDoc, arrayUnion, collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import useGetAlbumById from '../../../hooks/useGetAlbumById';
import { auth, db } from '../../../firebase';
import useGetUser from '../../../hooks/useGetUser';
import useGetBenefits from '../../../hooks/useGetBenefits';

function AddToAlbum(albumId) {
  console.log(albumId)
  const businessId = albumId.id
    

    const storage = getStorage()
    const data = null 
    
    const albums = useGetAlbumById(albumId.id)
 
    
    const navigate = useNavigate()
    const [docId, setdoc] = useState(null)
const [loading, setloading] = useState(false)
 

    const initialValues = {      
        name: data?.name,
        description: data?.description,  
      };
    
      const validationSchema = Yup.object().shape({
           name: Yup.string().required("Name is required"),
           description: Yup.string().required("Description is required"),
      });  
      
   
    //  const handleUpload = () => {
       
    //   if (images.length === 0) {
    //     alert("select an image to upload")
    //        return;
    //   }
    
    //   const image = images[0];
    //   uploadImage(image)
    //   setUploading(true);
    //   setProgress(0);
     
     
    // };
    
    // const uploadImage = async (image) => {    
    //     const id = Date.now()
    //       const storageRef = ref(storage, `business/${image.name}_${id}`);
    
    //       uploadBytes(storageRef, image).then((snapshot) => {
    //         console.log('Uploaded a blob or file!', snapshot);
    //         getDownloadURL(snapshot.ref).then(async (downloadURL) => {
    //             console.log('File available at', downloadURL);
    //             const userDocRef = doc(db, 'business', albumId.id, "albums", docId);
                
    //                     await updateDoc(userDocRef, {
    //                     images: arrayUnion(downloadURL),
    //                     });
    //           });
    //           setloading(false)
    //           navigate(-1)
    //       });
    // }; 
  
    const benefits = useGetBenefits()
 
  return (
    <Stack spacing={3} sx={{mt:3}}>
    <Typography variant='h6'>Add an Album ({albums?.length}/ {benefits?.features?.albums})</Typography>
    {!docId && albums?.length  < benefits?.features?.albums && <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values,  { resetForm }) => {
                            setloading(true)
                        console.log(values);
                        const docRef = await addDoc(collection(db, `business`, albumId.id, "albums"),  {...values, businessId : albumId.id,  userId : auth.currentUser.uid, createdAt : Date.now()});
                        setdoc(docRef.id)
                        navigate(`/dashboard/uploadImages/${docRef.id}`, 
         {state : {...values, albumId: albumId.id, id:docRef.id, imagesPerAlbum:benefits?.features?.imagesPerAlbum}})
                            console.log("Document written with ID: ", docRef.id);
                            setloading(false)
                                    }}
                    >
                        {(formik) => (
                        <Form>
                        <Stack direction={"row"} spacing={3} sx={{mb:2}}>
                            <Field
                            fullWidth
                            as={TextField}
                            type="text"
                            name="name" 
                            label="Album Name"
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            /> <br/><br/>
                          
                            <Field
                            fullWidth
                            as={TextField}
                            type="text"
                            name="description" 
                            label="Description"
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            /> <br/><br/>
                            
                            </Stack>
                         
                                {loading ? <Typography>Creating an Album</Typography> : 
                                <LoadingButton loading={loading} variant='contained'   size={"large"} color="warning" disabled={!formik.isValid} type='submit'>Next</LoadingButton>   }
                
                            </Form>
                        )}
                            </Formik>}


<Grid container spacing={1}>

{albums && albums?.sort((a, b) => a.name.localeCompare(b.name)).map((g, index)=>(
    <Grid item xs={12} md={12} lg={12} key={index}>
    <Stack direction={"row"} spacing={3} justifyContent={"space-between"} alignItems={"center"}>
        <Typography>{g.name}</Typography> 
         <Button variant='contained' color='success' 
         onClick={()=>navigate(`/dashboard/uploadImages/${g.id}`, 
         {state : {...g, albumId: albumId.id, imagesPerAlbum:benefits?.features?.imagesPerAlbum}})}>Manage   Album</Button>
       {/* {benefits?.features?.imagesPerAlbum - (g.images?.length || 0 )} */}
    </Stack>
    </Grid>
))  }
</Grid>
 
    </Stack>
  )
}


export default AddToAlbum


// eslint-disable-next-line no-lone-blocks
{/* <Grid container spacing={1}>
{g.images?.slice(0,3).map((h, index)=>(
<Grid item xs={12} md={3} key={index}>
    <StyledProductImg alt={h} key={index} src={h} />
</Grid>
))}
</Grid> */}