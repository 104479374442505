import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui 
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar'; 
import NotificationsPopover from './NotificationsPopover';
import {db, auth, onAuthStateChanged} from '../../../firebase'
import useResponsive from '../../../hooks/useResponsive';
// ----------------------------------------------------------------------

const NAV_WIDTH = 180;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
 
  const navigate = useNavigate()
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
       
        setUser(true);
      } else {
      
        setUser(false);
      }
    });
  }, [user]);

   
  const isDesktop = useResponsive('up', 'lg');
 

  return (
    <StyledRoot>
      <StyledToolbar>

        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1,  display:"flex", justifyContent:"center", alignItems:"center" }} >
        {!isDesktop && <img src="/assets/logo.png" style={{ width:70}}  alt="login" />}
        </Box>
        <Searchbar />

        {user ? <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 2,
          }}
        >
           <NotificationsPopover />
           
        </Stack> :<Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 2,
          }}
        >
        <Button variant='contained' onClick={()=>navigate("/login")} color="success">Get Started</Button>
        </Stack>}
      </StyledToolbar>
    </StyledRoot>
  );
}
