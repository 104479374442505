import { Button, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { push, ref } from 'firebase/database'; 
import { auth,  realdb } from '../../../firebase';

function HandleReply({close, comment, state, threadId }) {
    console.log(state)
    const [replyText, setReplyText] = useState('');
// if true then it has comments already
    const handleReply =async () => {
        // Create a new reply
        if (replyText.trim() !== '') {
          const reply = {
            text: replyText,
            timestamp : Date.now(),
            author: auth.currentUser?.uid, // You can replace this with the authenticated user's ID
          };
          if(state)
          {
            push(ref(realdb, `${comment.id}/replies`), reply);
          }
          else{
            push(ref(realdb, `${threadId}/replies`), reply);
          }
       
          setReplyText('');
          close()
        }
      };
      
  return (
   
        
                <Stack   alignItems={"center"}  sx={{p:2}}>
                <Typography variant="h6" sx={{mb:3}}>{comment?.text}</Typography>
          <TextField fullWidth
            type="text" sx={{mb:3}}
            placeholder={`${comment?.text === undefined ? "Share your thoughts" : `Reply to ${comment?.text}`}`}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
          <Button variant='contained' fullWidth color="warning" type="button" onClick={handleReply}>Reply</Button>
        </Stack>
   
   
  )
}

export default HandleReply