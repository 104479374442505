import { Helmet } from 'react-helmet-async'; 
import { Box, Button,  Card,  Container, Dialog, Grid, MenuItem, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetExpenseByProjectId from '../../../hooks/useGetExpenseByProjectId';
import { auth, db } from '../../../firebase';
import { fNumber } from '../../../utils/formatNumber';
import AttachFile from './AttachFile';
import AddExpense from './AddExpense';
import ProjectTimeLine from './ProjectTimeLine';


function ManageProject() {

  const [open, setOpen] = useState(false);
  const [openEx, setOpenEx] = useState(false);
const [selectedExpense, setselectedExpense] = useState(null)

  const handleClickOpen = (ex) => {
    setselectedExpense(ex)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEx = () => {
    setOpenEx(true);
  };

  const handleCloseEx = () => {
    setOpenEx(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    const location = useLocation()
    const data = location.state
    
      const expenses = useGetExpenseByProjectId(data.id) 
      const calculateTotalAmount = (expenses) => expenses?.reduce((total, expense) => total + expense.amount, 0);
      
      // Calculate the total amount
      const totalAmount = calculateTotalAmount(expenses);

      const deleteExpense = async (ex) =>{
        await deleteDoc(doc(db, "projects", data.id, "expenses", ex.id)) 
      }
  return (
    <>
      <Helmet>
        <title>Project Page</title>
      </Helmet>

      <Dialog
        open={open}
        onClose={handleClose}
      ><AttachFile handleClose={handleClose} projectId={data.id} expense={selectedExpense} />
      </Dialog>
  
      <Dialog
        open={openEx}
        onClose={handleCloseEx}
      ><AddExpense handleClose={handleCloseEx} projectId={data.id}  />
      </Dialog>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0} p={3}>
          <Typography variant="h4" gutterBottom>
            {data.name}<br/>
            <Typography component={"span"}>
                {data.description}
            </Typography>
          </Typography>         
          <Typography variant="h4" gutterBottom>
          {fNumber(totalAmount)} <br/>
            <Typography component={"span"}>
            {data.start}
            </Typography>
          <br/><Button variant='contained' color='success' onClick={()=>handleClickOpenEx(true)}>Add expense</Button>     
          </Typography>    
        </Stack>
 

      <Tabs value={value}  variant="scrollable" onChange={handleChange} sx={{mb:4}}>
        <Tab label="Costs" />
        <Tab label="Milestones" />
        </Tabs>

      {value ===0 &&
      <Container>
      
        <Grid container spacing={3}>
            {expenses && expenses.map((ex, index)=>(
                <Grid item xs={12} md={4} key={index}>
                    <Card sx={{p:3}}>
                        <Box>
                            <Typography variant="h5">{ex.expenseCategory}<br/>
                            <Typography component={"span"}>{ex.description}</Typography><br/>
                            <Typography component={"span"}>{fNumber(ex.amount)}</Typography>
                            </Typography>
                            <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
                            {ex.files?.map((f, index)=>(
                              <Button variant='text' target='_blank' href={f} key={index}>File</Button>
                            ))}
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                            <Button color='success' size="small" variant='contained' onClick={()=>handleClickOpen(ex)}>Attach files</Button>
                            <Button color='error' size="small" variant='outlined' onClick={()=>deleteExpense(ex)}>Delete Expense</Button>
                            </Stack>
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>

      </Container>}

      {value === 1 && <ProjectTimeLine id={ data.id} />}
    </>
  )
}

export default ManageProject