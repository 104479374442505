import  {useState, useEffect} from 'react'
import { collection,  onSnapshot, or, orderBy, query, where } from 'firebase/firestore';
 
import {  auth, db,} from '../firebase';

function useGetInboxById(id, seleectedBusiness) { 

        const [user, setuser] = useState(null)
        useEffect(() => {
          if(id === undefined || id === null || seleectedBusiness === undefined || seleectedBusiness === null){
            return
          }
            const q = query(collection(db, "inbox"), 
            or(where("businessId", "==", seleectedBusiness), where("userId", "==", id) ))
            onSnapshot(q, (querySnapshot) => {        
             const data =[]
             querySnapshot.forEach((doc) => {
                 const asd = {
                   ...doc.data(),
                   id: doc.id
                 }
                data.push(asd)      
               })
               
               setuser(data)
              });
        }, [id, seleectedBusiness])
      return user
    }

export default useGetInboxById