 import  {useState, useEffect} from 'react'
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
 
import {  db } from '../firebase';

const useGetAllUsers = () => {
 
  const [user, setuser] = useState(null)
  useEffect(() => {
      const q = query(collection(db, "app_users"))
      onSnapshot(q, (querySnapshot) => {        
       const data =[]
       querySnapshot.forEach((doc) => {
           const asd = {
             ...doc.data(),
             id: doc.id
           }
          data.push(asd)      
         })
         
         setuser(data)
        });
  }, [])
return user
}


export default useGetAllUsers

 