import  {useState, useEffect} from 'react'
import { collection,  onSnapshot, query, where } from 'firebase/firestore';
 
import {  db,} from '../firebase';

function useGetMarketplaceById(id) {

    
        const [user, setuser] = useState(null)
        useEffect(() => {
          if(id === undefined  || id === null)
          {
            return
          }
            const q = query(collection(db, "events"), where("userId", "==", id ))
            onSnapshot(q, (querySnapshot) => {        
             const data =[]
             querySnapshot.forEach((doc) => {
                 const asd = {
                   ...doc.data(),
                   id: doc.id
                 }
                data.push(asd)      
               })
               
               setuser(data)
              });

        }, [id])
      return user
    }

export default useGetMarketplaceById