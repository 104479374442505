import { LoadingButton } from '@mui/lab';
import { AppBar, Toolbar, Typography, Button, Stack, TextField, Divider, LinearProgress, CircularProgress, Card } from '@mui/material'
import { addDoc, arrayUnion, collection, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { auth, db } from '../../../firebase';
// eslint-disable-next-line react/prop-types
function AttachFile({handleClose, expense, projectId}) {
    const storage = getStorage()    
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);


    const handleImageChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
          const file = event.target.files[0];
          setImages([file]);
        }
      };

      const handleUpload = () => {  
        const image = images[0];
        uploadImage(image)
             };
      
      const uploadImage = async (image) => {  
        setloading(true)
        if(image){
          const id = Date.now();

          const fileRef = ref(storage, `expense/${image.name}_${id}`);
          const uploadTask = uploadBytesResumable(fileRef, image);
        // Listen to state_changed event to track progress
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress)
            setUploadProgress(progress);
          },
          (error) => {
            console.error(`Error uploading ${image.name}: ${error.message}`);
          
          },
          () => {
            // Upload completed successfully
            getDownloadURL(fileRef).then(async (downloadURL) => {
              console.log(`File ${image.name} available at ${downloadURL}`);
              
              const userDocRef = doc(db, 'projects', projectId, 'expenses', expense.id);
              await updateDoc(userDocRef, {
                files: arrayUnion(downloadURL),
              });
              handleClose()
            });
          }
        ); }
    }
       
console.log(images)

  return (
    <div>
           <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
          
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
             {expense.expenseCategory}
            </Typography>
             
          </Toolbar>
        </AppBar>
        <Stack spacing={3} alignItems={"center"} p={3} >
        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
             Add files related to this expense item for easy tracking
            </Typography>

            <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                  <Card sx={{p:1, px:3, border:" thin solid #ED6C02"}} 
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>Click here to select files</p>
                    </Card>
                    <br/>
                  </div>
                )}
              </Dropzone>

 
        {images.length > 0 && 
        <>
        <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />
      <LoadingButton loading={loading} fullWidth variant="contained" onClick={()=>handleUpload()} color="warning">Upload</LoadingButton>
      </>}
        </Stack>
    </div>
  )
}
export default AttachFile