import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Card, TextField, Box, Grid, CircularProgress } from '@mui/material';
import { Timeline,TimelineSeparator,TimelineDot, TimelineItem, TimelineOppositeContent, TimelineConnector, TimelineContent, LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { addDoc, arrayUnion, collection, doc, updateDoc } from 'firebase/firestore';
import Dropzone from 'react-dropzone';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { auth, db } from '../../../firebase';
import useGetMilestonesByProjectId from '../../../hooks/useGetMilestonesByProjectId';

// ----------------------------------------------------------------------

export default function ProjectTimeLine({id}) {

    const [post, setpost] = useState("")
    const [title, setTitle] = useState("")
    const [selectedDate, setselectedDate] = useState(new Date().toLocaleDateString())
    const [loading, setloading] = useState(false)
    const [images, setImages] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    const milestones = useGetMilestonesByProjectId(id)


    const postIt =async () =>{
        setloading(true)
        if (!title || !post || !selectedDate) {
            alert("Fill in all the fields.")// Handle the case where any of the required fields are missing
            console.error("Required fields are missing!");
            return
          } 
        await addDoc(collection(db, "projects", id, "milestones"), 
         { 
            title, post, setDate : selectedDate,  userId : auth.currentUser.uid, createdAt : Date.now()}).then((doc)=>{
            setpost("")
            setTitle("")
            setselectedDate("")
            console.log("posted")
            if(images.length > 0 )
         {
             handleMultipleImageChange(doc.id)
         }  
         setloading(false)   
         }).catch((error)=>{
            setloading(false)
         }); 
         console.log(doc)
                  
    }

    const storage = getStorage()
 
    const handleMultipleImageChange = (docId) => {
        if (images && images.length > 0) { 
          const selectedImages = Array.from(images);       
          if (selectedImages.length > 5) { 
            const firstTenImages = selectedImages.slice(0, 5);
            uploadImages(firstTenImages, docId) 
          } else {
            uploadImages(selectedImages, docId) 
          }
        }
      };

    const uploadImages = async (images, docId) => {
      const storageRef = ref(storage, `milestones/`); // Define the storage reference
    
      // eslint-disable-next-line no-async-promise-executor
      const uploadPromises = images.map((image) => new Promise(async (resolve) => {
        const fileRef = ref(storageRef, image.name);
        const uploadTask = uploadBytesResumable(fileRef, image); 
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.round(progress)) 
          },
          (error) => {
            console.error(`Error uploading ${image.name}: ${error.message}`);
            resolve(null);
          },
          () => {
            // Upload completed successfully
            getDownloadURL(fileRef).then(async (downloadURL) => {
              console.log(`File ${image.name} available at ${downloadURL}`);
               const userDocRef = doc(db, 'projects', id, "milestones", docId );
              await updateDoc(userDocRef, {
                images: arrayUnion(downloadURL),
              });
                resolve(downloadURL);
            });
          }
        );
      }));
  
    try {
      const uploadedImageUrls = await Promise.all(uploadPromises);
      console.log('All images uploaded:', uploadedImageUrls);
      setImages([]); // Clear the uploaded images
   
       } catch (error) {
      console.error('Error uploading images:', error);
       
    }
    };

    console.log(milestones)
  return (
   <>
      <Helmet>
        <title> Milestones</title>
      </Helmet>

      <Container  >
     
 <Grid container spacing={3}>
    <Grid item xs={12} md={3}>
    <Card sx={{p:3}}>
    <Box>
    <Stack  spacing={2} alignItems={"center"}>
        <TextField
        fullWidth  
        label="Title"
        value={title} disabled={loading}
        onChange={(event) => setTitle(event.target.value)}
      />
        <TextField
        fullWidth multiline rows={2}
        label="Share your milestone"
        value={post} disabled={loading}
        onChange={(event) => setpost(event.target.value)}
      />
        <TextField
        fullWidth type='date'
        label="Set date"
        value={selectedDate} disabled={loading}
        onChange={(event) => setselectedDate(event.target.value)}
      />
       <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                    <div
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} multiple />
                      <p>Click here to select files</p>
                    </div>
                    <br/>
                  </div>
                )}
              </Dropzone>

     
        {images.length > 0 && 
         
        <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
        <LoadingButton fullWidth loading={loading} variant="contained" color="warning" onClick={()=>postIt()}>Post</LoadingButton>
 
        </Stack>
         
    </Box>
   </Card>
    </Grid> 
    <Grid item xs={12} md={9}>
    <Timeline position="alternate">
    {milestones?.map((m, index)=>(
        <TimelineItem key={index}>
        <TimelineOppositeContent color="text.secondary">
          <Typography sx={{fontWeight:"bold"}}>{m.setDate}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="warning" />
          <TimelineConnector  />
        </TimelineSeparator>
        <TimelineContent>
            <Card sx={{p:3}}>
                <Typography sx={{fontWeight:"bold"}}>{m.title}</Typography>
                <Typography variant='body1'>{m.post}</Typography>
                <Grid container spacing={2}>
                {m.images && m.images.map((i, index)=>(
                    <Grid item xs={12} md={12} key={index}>
                    <img alt={m.title} src={i}  style={{width:"100%"}} />
                    </Grid>
                    ))}
                </Grid> 
            </Card>
        </TimelineContent>
      </TimelineItem>
    ))} 
    </Timeline>
    </Grid>
 </Grid>
       

    
      </Container>
    </>
  );
}
 