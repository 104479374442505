import React, { useState } from 'react'
import { Typography, Stack, CircularProgress, TextField, Box, Card, Divider, useTheme} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import useGetMessagesById from '../../../hooks/useGetMessagesById'
import { auth, db } from '../../../firebase'
import { fToNow } from '../../../utils/formatTime'
 

function ViewMessages({id}) {
    const data = id
    const messages = useGetMessagesById(id.id)
    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
    
        };
        return date.toLocaleDateString('en-GB', options);
      } 

    const [post, setpost] = useState("")
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);

    
      const handleUpload = (id , state) => {
        if (post.trim() === "") {
          alert("Write something to continue");
          return;
        }
        uploadPost(id, state);
         
      };

      const uploadPost = async (id) => {
        setloading(true)
        // Create a new post document
        const postDoc = {
          post,
          createdAt: Date.now(),
          businessId : data.businessId, 
          businessOwner : data.businessOwner,
          userId: auth.currentUser.uid,
            lastUpdate : Date.now()
        }; 
       
            const timeDate = Date.now()
            await updateDoc(doc(db, "inbox", id), { 
                lastUpdate : timeDate,
                status : "UNREAD",
                lastSender : auth.currentUser?.uid
            }); 
            await addDoc(collection(db, "inbox", id, "messages"), postDoc); 
            setloading(false)
            setpost("")
       
       
      };  
      
   
       
      const testing = async () =>{ 
            const chatId = doc(db, "inbox", id.id)
            const docSnap = await getDoc(chatId)
 console.log(id)
            if(docSnap.exists()){
                console.log("chat exists")
                handleUpload(id.id, true)
            }
      }

const theme = useTheme()

console.log(theme.palette.success.dark)
  return (
    messages && 
    <Stack  >
 
         
        <Card sx={{p:2, mb:3}}>
    <Box>    
        <Stack   direction={"row"} alignItems={"center"} justifyContent={"center"}   >
        
        <TextField
        fullWidth   multiline
         type="text"
        label="Send message"
        value={post}
        onChange={(event) => setpost(event.target.value)}
      />

  {uploadProgress > 0 && <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
     
      <LoadingButton loading={loading} size={"large"} sx={{ml:1}}  variant="contained" onClick={()=>testing()} color="warning">Send</LoadingButton>
         </Stack>
        </Box>
    </Card>
 

 {messages.map((m, index)=>(
        m.userId === auth.currentUser?.uid ? 
        <Card sx={{p:3, display:"flex", mb:2, alignSelf:"flex-end"}} key={index}>
        <Typography  sx={{textAlign: "right",}}>{m.post}  
        <br/><Typography component={"span"} sx={{fontSize:12, color:theme.palette.success.dark}}>{fToNow(m.createdAt)}</Typography>
        </Typography>
 
        </Card>

         : 
         <Card sx={{p:3, display:"flex", alignSelf:"flex-start"}} key={index}>
         <Typography  sx={{textAlign: "left", }}>{m.post}
         <br/><Typography component={"span"} sx={{fontSize:12}}>{fToNow(m.createdAt)}</Typography>
         </Typography>
       
         </Card>
    )) 
    }
 
  
        
    
     
    </Stack>
  )
}

export default ViewMessages