const eventTypes = [
    "Trade Fairs",
    "Music & Concerts",
    "Health, Fitness & Wellness",
    "Comedy Shows",
    "Sports",
    "Theater & Drama",
    "Painting Exhibition",
    "Government Events",
    "Conferences & Seminars",
    "Weddings & Bridal Events",
    "Food & Wine Festivals",
    "Art & Cultural Exhibitions",
    "Business & Networking",
    "Fashion & Beauty Events",
    "Agriculture & Climate Change",
    "Virtual & Online Events",
    "Books & Literature",
    "Nature & Adventure",
    "Tech & Innovation",
    "Family & Kids",
    "Other"
  ];

  export default eventTypes
  