import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Divider, Button, Card, Box, CircularProgress, Grid, Avatar, useTheme } from '@mui/material';
import { signOut } from 'firebase/auth';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Dropzone from 'react-dropzone';
import useGetUser from '../../hooks/useGetUser';
import { auth, db } from '../../firebase';
import useGetExpired from '../../hooks/useGetExpired';

// ----------------------------------------------------------------------

export default function MyProfile() {

    const user = useGetUser(auth.currentUser?.uid)
    const expiry = useGetExpired()
    const navigate = useNavigate()
    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
    
        };
        return date.toLocaleDateString('en-GB', options);
      }

      function signOut_ () {
        signOut(auth).then(() => {
        navigate("/")
          // Sign-out successful.
        }).catch((error) => {
          console.log(error)
          // An error happened.
        });
      }  

      
    const storage = getStorage()
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);

    
      const handleUpload = () => {         
        if (images.length > 0) {
          uploadImage(images[0]);
        }  
      }; 
   
        const uploadImage = async (image) => {  
          setloading(true)
          if(image){
            const _id = Date.now();
  
            const fileRef = ref(storage, `profilePictures/${image.name}_${_id}`);
            const uploadTask = uploadBytesResumable(fileRef, image);
          // Listen to state_changed event to track progress
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress)
              setUploadProgress(progress);
            },
            (error) => {
              console.error(`Error uploading ${image.name}: ${error.message}`); 
            },
            () => {
              // Upload completed successfully
              getDownloadURL(fileRef).then(async (downloadURL) => {
                const userDocRef = doc(db, 'app_users', auth.currentUser?.uid);                
                        await updateDoc(userDocRef, {
                        profile_image: downloadURL,
                        updatedAt : Date.now()
                        });
      });
   
      setImages([])
      setUploadProgress(0)
      setloading(false)
            }
          ); }
      } 
     

  
     
  return (
   <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>

      {user && <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h6" gutterBottom>
            My Profile
          </Typography>  
          <Button onClick={()=>signOut_()} variant='outlined' color="error">Log out</Button>   
         
        </Stack>  
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}> 
        <Avatar sx={{ bgcolor: 'background.neutral', width : 128, height:128 }} src={user?.profile_image} />
        <Divider sx={{my:3}} />
        <Typography variant="body1" gutterBottom>
            {user?.fullName}<br/>
            <Typography component={"span"} sx={{fontSize:12}} gutterBottom>
            Full Name
          </Typography>  
          </Typography>

          {user?.phoneNumber && <><Divider sx={{my:3}} />
        <Typography variant="body1" gutterBottom>
            {user?.phoneNumber}<br/>
            <Typography component={"span"} sx={{fontSize:12}} gutterBottom>
            Phone Number
          </Typography>  
          </Typography></>}

          {user?.email && <><Divider sx={{my:3}} />
        <Typography variant="body1" gutterBottom>
            {user?.email}<br/>
            <Typography component={"span"} sx={{fontSize:12}} gutterBottom>
           Email
          </Typography>  
          </Typography>
          <Divider sx={{my:3}} />
          </>}
          <Typography variant="body1" gutterBottom>
            {user?.account === "PRO" ? "PREMIUM" : user?.account}<br/>
            <Typography component={"span"} sx={{fontSize:12}} gutterBottom>
            Account
          </Typography>  
          </Typography>
          <Divider sx={{my:3}} />
          <Typography variant="body1" gutterBottom>
            {user?.accountUpdatedOn}<br/>
            <Typography component={"span"} sx={{fontSize:12}} gutterBottom>
            Account updated on 
          </Typography>  
          </Typography>
          <Divider sx={{my:3}} />
          {/* {`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`} */}
          <Typography variant="body1" gutterBottom>
           {user?.accountUpdatedDate}<br/>
            <Typography component={"span"} sx={{fontSize:12}} gutterBottom>
            Account active until  
          </Typography>  
          </Typography>
          
          <Divider sx={{my:3}} />
          <Typography variant="body1" gutterBottom>
          Account Status<br/>{user?.account_status}<br/>
              <br/>
            <Typography component={"span"} sx={{fontSize:12}} gutterBottom>
            {expiry ? "Account is Active" : "Account is Expired"}
          </Typography>
            
          </Typography>
          <Divider sx={{my:3}} />
          {user?.admin && 
              <Button  variant="contained" color="warning" onClick={()=>navigate("/dashboard/admin")}>Admin Access</Button>
           
          }
<br/> <Divider sx={{my:3}} />
          <Button variant="contained" color="warning" onClick={()=>navigate("/dashboard/editProfile")}>Edit Profile</Button>
          
       
        </Grid>
        <Grid item xs={12} md={6}>
        <Stack mb={5}>
          <Typography variant="h6" gutterBottom>
            Upload Profile Image
          </Typography>      
           
          <Card sx={{p:3}}>
    <Box>    
        <Stack      >
       
  {uploadProgress > 0 && <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
    <Stack direction={"row"} sx={{mt:2}} justifyContent={"space-between"}>
    <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                    <Card sx={{p:1, px:3, border:" thin solid #ED6C02"}} 
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>Click here to select image</p>
                      {images.length === 1 && images[0].name}
                    </Card>
                    </div>
                )}
              </Dropzone>
    </Stack>
  

    <br/>
        </Stack>
        </Box>
      <LoadingButton loading={loading}   variant="contained" onClick={()=>handleUpload()} color="warning">Upload</LoadingButton>
    </Card>
        </Stack>

        <Box sx={{ flexGrow: 1, p:2 }} >
      <Button href='/assets/findme_terms_and_conditions.pdf' target='_blank' style={{fontSize:12}}>Terms & Conditions</Button>         
     </Box>
        </Grid>
      </Grid>
      
        
         
              
      </Container>}
    </>
  );
}
