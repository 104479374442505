import  {useState, useEffect} from 'react'
import { collection,  getDocs,  onSnapshot, query, where } from 'firebase/firestore';
 
import {  db,} from '../firebase';

function useGetExpenseByProjectId(albumId) {

  // const asyncFn = async () => { .... };
  // asyncFn();
    console.log(albumId,"hook")
        const [user, setuser] = useState(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect( () => {
          
            const q =  query(collection(db, "projects", albumId, "expenses"));
            onSnapshot(q, (querySnapshot) => {        
              const data =[]
              querySnapshot.forEach((doc) => {
                  const asd = {
                    ...doc.data(),
                    id: doc.id
                  }
                 data.push(asd)      
                })
                
                setuser(data)
               });

       
        }, [albumId])
      return user
    }

export default useGetExpenseByProjectId