// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const noauthnavConfig = [
  {
    title: 'places',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'events',
    path: '/dashboard/events',
    icon: icon('ic_cart'),
  },  
  {
    title: 'explore',
    path: '/dashboard/explore',
    icon: icon('ic_blog'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
];

export default noauthnavConfig;
