/* eslint-disable react/prop-types */
import React from 'react'
import { AppBar, Toolbar, Typography, styled, Stack, Card, Box,   Grid, Divider, Button } from '@mui/material'
import Slider from 'react-slick'
import useGetUser from '../../../hooks/useGetUser';
import { fNumber } from '../../../utils/formatNumber';

function VIewItem({handleClose, data}) {

    console.log(data) 
    const StyledProductImg = styled('img')({
        top: 0, bottom:0, left:0, right:0, height:300, objectFit:"cover"
      });

      const user = useGetUser(data.userId)

      const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        
      };

      console.log(user)
  return (
    <div>
        

        <Grid item xs={12} md={4}  >
            <Card  >
                <Box>
               
                <Slider {...settings2}>
                    {data.images?.map((i, index)=>(
                      <StyledProductImg key={index} alt={data.eventTitle} src={i} />
                    ))}
                </Slider>
                    <Stack spacing={2} sx={{p:2}}>
                    <Typography variant="h3">{data.eventTitle}</Typography>
                    <Divider sx={{my:3}} >Tickets</Divider>
                         <Typography variant="body1">VIP : {fNumber(data.vip)}, <br/>General Admission : {fNumber(data.generalAdmission)}, <br/>Preorder : {fNumber(data.preorder)}
                        <br/><Typography component="span"> {data.category}</Typography></Typography>
                        <Stack direction="row" justifyContent={"space-between"}>
                             <Typography>{data.eventDescription}</Typography>
                        </Stack>
                    </Stack>
                    <Divider sx={{my:3}} >Details</Divider>
                    <Stack spacing={2} sx={{p:2}}>
                         <Typography variant="h5">{data.category} </Typography>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Typography>{data.time}</Typography>
                            <Typography>{data.date}</Typography>
                            <Typography>{data.location}</Typography>
                        </Stack>
                    </Stack>
                    <Divider sx={{my:3}} >Contact</Divider>
                    {user && <Stack spacing={2} sx={{p:2}}>
                        <Stack direction="row" justifyContent={"space-between"}>
                         <Typography variant="h5">{user.fullName} </Typography>
                            <Typography>{user.phoneNumber}</Typography>
                            <Typography>{user.email}</Typography>
                           </Stack>
                    </Stack>}
                    <Divider sx={{my:3}}/>
                   

                </Box>
            </Card>
        </Grid>
    </div>
  )
}

export default VIewItem