import {useEffect, useState} from 'react'
import useGetUser from './useGetUser'
import { auth } from '../firebase'

function useGetExpired() {
    
    const user = useGetUser(auth.currentUser?.uid)
    const [expired, setexpired] = useState(null)

    useEffect(() => {
       
      const isAccountExpired = () =>{
       const d1 = new Date(user?.accountUpdatedDate)
    
       const d2 = new Date(new Date())
        if(user?.account === "FREE"){
          setexpired(true)
          return
        }
       if(d1 > d2){
        setexpired(true)
         }
       else{
        setexpired(false)
         }
        
         
      }

     isAccountExpired()
    }, [user])
    console.log(expired)

  return  expired
}

export default useGetExpired