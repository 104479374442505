import  {useState, useEffect} from 'react'
import { collection,  collectionGroup,  onSnapshot, orderBy, query, where } from 'firebase/firestore';
 
import {   db,} from '../firebase';

function useGetAllExploreAlbums() { 
    
        const [user, setuser] = useState(null)
        useEffect(() => {
       
            const q = query(collectionGroup(db, "albums"), orderBy("createdAt", "desc"))
            onSnapshot(q, (querySnapshot) => {        
             const data =[]
             querySnapshot.forEach((doc) => {
                 const asd = {
                   ...doc.data(),
                   id: doc.id
                 }
                data.push(asd)      
               })
               
               setuser(data)
              })
        }, [])
      return user
    }

export default useGetAllExploreAlbums