import React, { useState, useEffect } from 'react';
import { ref, set,getDatabase, onValue, query, orderByChild, get, push, update } from 'firebase/database';
import { Box, Card, CircularProgress, Dialog, Stack, TextField, Typography } from '@mui/material';
import { auth, realdb } from '../../../firebase';// Import your Firebase configuration
import CommentInput from './CommentInput';
import HandleReply from './HandleReply';
import { fToNow } from '../../../utils/formatTime';
import UserName from '../../../_mock/UserName';

const CommentDisplay = ({ comment, level=0, threadId, results})=> {
 
    const [showReplies, setShowReplies] = useState(level === 0);
    const [replies, setReplies] = useState([]);
 console.log(comment)
    
    useEffect(() => {
      // Firebase Realtime Database reference for replies
      
      const repliesRef =  ref(realdb, `${comment?.id ? comment?.id : threadId}/replies`);
 
      // Listen for changes in replies
      onValue(repliesRef, (snapshot)=>{
        if (snapshot.exists()) {
            const replyData = snapshot.val();
            const replyArray = Object.keys(replyData).map((key) => ({
                id: key,
                ...replyData[key],
              }));
            setReplies(replyArray);
          }
      })
      
      
  
    }, [comment?.id]);
   
    const handleShowReplies = (state) =>{
         setShowReplies(state)
    }

   
    const [openView, setopenView] = useState(false)
    const [selectedItem, setselectedItem] = useState(null)
    const handleClickOpenView = (ex) => {
        console.log(ex)
        // const updates = {}; 
        // updates[`${threadId}/replies/${ex.id}/`] = {count : 1};
        // update(ref(realdb), updates)
        setopenView(true);
        setselectedItem(ex)
      };
    
      const handleCloseView = (ex) => {
        setopenView(false);
       
      };
 
    return (

        results === null ? <CircularProgress color="warning" /> :  results?.length === 0 ? 
            auth.currentUser && <HandleReply close={handleCloseView}  threadId={threadId}   state={false}  />
:
        <Card sx={{p:1, mb:4}}>
             <Dialog
        open={openView}
        onClose={handleCloseView}
      >
       {auth.currentUser && <HandleReply close={handleCloseView}   comment={selectedItem} threadId={threadId} state  />}
      </Dialog>
     
      
        {level=== 0 ? null : 
           <Stack>
        <UserName id={comment.author} />
        <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
        <Typography>  {comment?.text}  </Typography>
        <Typography sx={{my:1, textAlign:"right"}} variant='overline'>{fToNow(comment.timestamp)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
        {auth.currentUser !== null && <Typography onClick={()=>handleClickOpenView(comment)}> Reply</Typography>}
        {replies?.length > 0 &&  
        <Typography  onClick={() => handleShowReplies(!showReplies, )}>{showReplies ? "Hide" : "Show"} {replies?.length}  Comments</Typography>}
    
        </Stack>
         </Stack>
        }
 
       
        {showReplies && (
          <div className="replies">
          
            {replies?.map((reply) => (
                <>
              <CommentDisplay key={reply.id} comment={reply} level={level+1} />
              
                </>
            ))}
       
          </div>
        )}
        
      </Card>
    );
}

export default CommentDisplay;


 
  