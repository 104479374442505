import React, { useEffect, useState } from 'react';
import useGetUser from './useGetUser';
import { auth } from '../firebase';

function useGetBenefits() {
 
    const user = useGetUser(auth.currentUser?.uid)

        const [accountDetails, setAccountDetails] = useState({});
    
        useEffect(() => {
            switch(user?.account) {
                case 'FREE':
                    setAccountDetails({
                        type: 'FOREVER',
                        price: 0,
                        features: {
                            businesses: 1,
                            albums: 1,
                            events: 1,
                            imagesPerAlbum: 5
                        }
                    });
                    break;
                case 'BASIC':
                    setAccountDetails({
                        type: 'YEARLY',
                        price: 'K50',
                        features: {
                            businesses: 3,
                            events: 3,
                            albums: 3,
                            imagesPerAlbum: 10,
                            pricingGuides: 3
                        }
                    });
                    break;
                case 'PRO':
                    setAccountDetails({
                        type: 'YEARLY',
                        price: 'K100',
                        features: {
                            businesses: 1000,
                            events: 1000,
                            verifiedAccount: true,
                            pricingGuides: 1000,
                            albums: 1000,
                            imagesPerAlbum: 1000,
                            dailyFeaturedPost: true,
                            verifiedBadge: true
                        }
                    });
                    break;
                default:
                    setAccountDetails({});
            }
        }, [user?.account]);
    
        return accountDetails
        // Rest of your component
    }
    


export default useGetBenefits