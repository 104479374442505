import { Container, Grid, Typography, Box, Button, Card, Stack, TextField } from '@mui/material'
import React,{useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom' 
import { addDoc, collection } from 'firebase/firestore';
import { onValue, ref } from 'firebase/database';
import { auth, db, realdb } from '../../../firebase';
 
import CommentDisplay from './CommentDisplay';
import HandleReply from './HandleReply';
import { fToNow } from '../../../utils/formatTime';
import UserName from '../../../_mock/UserName';

function ViewBlogPost() {
    const location = useLocation()
    const data = location.state
 const navigate = useNavigate() 
   
const [results, setresults] = useState(null)
    useEffect(() => {
      // Firebase Realtime Database reference for replies
      
      const repliesRef =  ref(realdb, `${data?.id}/replies`);
 
      // Listen for changes in replies
      onValue(repliesRef, (snapshot)=>{
        if (snapshot.exists()) {
            const replyData = snapshot.val(); 
            const replyArray = Object.keys(replyData).map((key) => ({
                id: key,
                ...replyData[key],
              }));
               
                setresults(replyArray);
                console.log("h1") 
          }
          else{

            setresults([])
            console.log("b1")
          }
      }) 
  
    }, [data?.id]);
    const [openView, setopenView] = useState(false)
    const handleCloseView = (ex) => {
      setopenView(false);
     
    };
    
    console.log(results)
  return (
    <Container>
    <Grid container spacing={3} >
        <Grid item xs={12} md={4}>
        <UserName id={data.userId} />
            <Typography sx={{mb:3}}>{data.post}</Typography>
            {data.image && <img src={data.image} width={"100%"} alt='data.post' />}
            <Typography sx={{mb:3}} variant="overline">{fToNow(data.createdAt)}</Typography>
            <br/>
            
            <Button color="warning" variant='contained' onClick={()=>navigate("/dashboard/userProfile", {state: data.userId})}>See more from Author</Button>
         </Grid>

         <Grid item xs={12} md={8}>
     <Stack  >
     {auth.currentUser !== null &&
     <div>
     {results?.length === 0 ? "" : <HandleReply close={handleCloseView}  threadId={data.id}   state={false}  />}

     </div>
 
   }
   <CommentDisplay threadId={data.id} results={results}  />
 
   </Stack>
         </Grid>
    </Grid>
    </Container>
  )
}

export default ViewBlogPost