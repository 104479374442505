import React, { useState } from 'react'
import { Tab, Tabs, Typography } from '@mui/material'
import useGetUser from '../../../hooks/useGetUser'
import { auth } from '../../../firebase'
import Free from '../Analytics/Free'
import Pro from '../Analytics/Pro'
import Basic from '../Analytics/Basic'

function Analytics() {
    const user = useGetUser(auth.currentUser?.uid)
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <div>
{/*        
        {user?.account === "FREE" && <Free />}
        {user?.account === "BASIC" && <Basic />}
        {user?.account === "PRO" && <Pro />} */}

        <Tabs value={value}  variant="scrollable" onChange={handleChange} sx={{mb:4}}>
  <Tab label="Overview" />
  <Tab label="Insights" />
  <Tab label="Performance" />
</Tabs>
    
    {value === 0 && <Free />}
    {value === 1 && <Basic />}
    {value === 2 && <Pro />}
  
    </div>
  )
}

export default Analytics