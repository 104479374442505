import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const getRandomNumber = () => [1, 2, 3, 4, 5, 6,7,8,9,10,11,12,13,14,15,16,17,18,19,20][Math.floor(Math.random() * 20)];

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
       

        {mdUp && (
          <StyledSection> 
            <img src={`./assets/images/${getRandomNumber()}.jpeg`} style={{objectFit:"cover", height:"100%"}}  alt="loggin" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
          <img src="./assets/logo.png" style={{objectFit:"cover", width:150}}  alt="login" />
          <br/>
               <Typography variant="body1" style={{fontSize:24, textTransform:"uppercase"}} gutterBottom>
            Explore and experience places, events, and activities around you | Making finding places easy
            </Typography><br/>
            <Typography style={{fontSize:18, textTransform:"capitalize"}}>Are you looking for a nice chill spot? Do want to have fun? Are you planning a wedding, birthday party or corporate event? Join Zambia's fastest growing app.</Typography>
           <br/> <Typography variant="h6" gutterBottom>
              Sign in
            </Typography>

            <Typography  style={{fontSize:18,}} sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" to="/register" style={{color:"inherit"}} >Get started</Link>
            </Typography> 
           
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
