const vendorCategories = [
  {
    "category": "Coffee Shops",
    "description": "Cozy places to enjoy your favorite coffee beverages.",
    "color": "#A52A2A"
  },
  {
    "category": "Bars & Entertainment",
    "description": "Places to unwind, enjoy drinks, and entertainment.",
    "color": "#FFD700"
  },
  {
    "category": "Wedding & Other Social Events",
    "description": "Services for special occasions and social gatherings.",
    "color": "#FF69B4"
  },
  {
    "category": "Liquor Stores",
    "description": "Shops specializing in alcoholic beverages.",
    "color": "#8B4513"
  },
  {
    "category": "Restaurants",
    "description": "Dining establishments offering various cuisines.",
    "color": "#32CD32"
  },
  {
    "category": "Lodges/Hotels",
    "description": "Accommodations for travelers with comfortable amenities.",
    "color": "#4169E1"
  },
  {
    "category": "Home Decor",
    "description": "Stores providing decorative items for homes.",
    "color": "#FF4500"
  },
  {
    "category": "Interior Design (Home & Office)",
    "description": "Design services for home and office spaces.",
    "color": "#6A5ACD"
  },
  {
    "category": "Travel Agencies",
    "description": "Agencies offering travel planning and booking services.",
    "color": "#20B2AA"
  },
  {
    "category": "Play Parks",
    "description": "Parks designed for recreational activities and play.",
    "color": "#32CD32"
  },
  {
    "category": "Kids Birthday Party",
    "description": "Services for organizing fun and memorable birthday parties for kids.",
    "color": "#FF69B4"
  },
  {
    "category": "Wildlife Adventure",
    "description": "Exciting adventures in nature to explore wildlife.",
    "color": "#228B22"
  },
  {
    "category": "Beaches and Resorts",
    "description": "Sandy shores and luxurious resorts for relaxation.",
    "color": "#FFD700"
  },
  {
    "category": "Swimming Pools",
    "description": "Facilities for swimming and aquatic activities.",
    "color": "#00BFFF"
  },
  {
    "category": "Ice Cream & Smoothies",
    "description": "Shops offering delicious frozen treats and smoothies.",
    "color": "#FF4500"
  },
  {
    "category": "Art Gallery & Cultural Ornaments",
    "description": "Galleries displaying artistic creations and cultural artifacts.",
    "color": "#800080"
  },
  {
    "category": "Gyms",
    "description": "Fitness centers with exercise equipment and classes.",
    "color": "#FFA500"
  },
  {
    "category": "Hair Salons",
    "description": "Salons providing hair styling and treatments.",
    "color": "#FF4500"
  },
  {
    "category": "Barbershop",
    "description": "Shops offering haircut and grooming services for men.",
    "color": "#000080"
  },
  {
    "category": "Spa/Massage",
    "description": "Relaxing spas and massage centers for wellness.",
    "color": "#9932CC"
  },
  {
    "category": "Dry Cleaning",
    "description": "Cleaning services for garments and textiles.",
    "color": "#4B0082"
  },
  {
    "category": "Men & Ladies Clothing",
    "description": "Stores offering fashionable clothing for men and women.",
    "color": "#800000"
  },
  {
    "category": "Children's Clothing",
    "description": "Clothing stores specializing in kids' apparel.",
    "color": "#FF69B4"
  },
  {
    "category": "Car Hire Services",
    "description": "Services providing rental cars for travel.",
    "color": "#008080"
  },
  {
    "category": "Museums",
    "description": "Institutions showcasing artifacts and historical items.",
    "color": "#A52A2A"
  },
  {
    "category": "Car Wash",
     "color": "#A52A2A"
  },
  {
    "category": "MakeUp Artists",
     "color": "#A52A2A"
  },
  {
    "category": "Cosmetics",
     "color": "#A52A2A"
  },
  {
    "category": "Skin Care",
     "color": "#A52A2A"
  },
  {
    "category": "Gift &  Flower Bouquets",
     "color": "#A52A2A"
  },
  {
    "category": "Cakes & Bakes",
     "color": "#A52A2A"
  },
  {
    "category": "Car Fitment Centre",
     "color": "#A52A2A"
  },
  {
    "category": "Photography",
     "color": "#A52A2A"
  },
  {
    "category": "Food & Beverages",
     "color": "#A52A2A"
  },
  {
    "category": "Dance Studio",
     "color": "#A52A2A"
  },
  {
    "category": "Sport & Gaming",
     "color": "#A52A2A"
  },
  {
    "category": "Natural Health & Wellness",
     "color": "#A52A2A"
  },
  {
    "category": "Other",
     "color": "#A52A2A"
  },
]



  
  export default vendorCategories