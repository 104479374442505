import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack,Tab, Tabs, Container, Typography, Card, Button, Grid, Divider, TextField, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import useGetDirectory from '../hooks/useGetDirectory';
import useGetPosts from '../hooks/useGetPosts';
import useGetMarketPlace from '../hooks/useGetMarketPlace';
import useGetAllExploreAlbums from '../hooks/useGetAllExploreAlbums';
import useGetAllUsers from '../hooks/useGetAllUsers';
import Transactions from '../sections/@dashboard/admin/Transactions';
import useGetInvoices from '../hooks/useGetInvoices';
import Blogs from '../sections/@dashboard/admin/Blogs';
import DashboardAppPage from './DashboardAppPage';
import useGetUser from '../hooks/useGetUser';

// ----------------------------------------------------------------------

export default function Admin() {
  const user = useGetUser(auth.currentUser?.uid)
    const businesses = useGetDirectory()?.sort((a, b) => a.name.localeCompare(b.name))
    const posts = useGetPosts()
    const events = useGetMarketPlace()
    const albums = useGetAllExploreAlbums()
    const users = useGetAllUsers()?.sort((a, b) => a.fullName.localeCompare(b.fullName))
    const transactions = useGetInvoices()
  const navigate = useNavigate()
    const [filtered, setfiltered] = useState([])
    const [filteredUsers, setfilteredUsers] = useState([])
    const [selectedfilter, setselectedfilter] = useState("ALL")
    const [selectedUsersfilter, setselectedUsersfilter] = useState("ALL")
    const [account, setAccount] = useState(null);
    const [selectedBusiness, setselectedBusiness] = useState(null)
    const [selectedUser, setselectedUserusiness] = useState(null)

    const updateStatus = async (status, id) =>{ 
      await updateDoc(doc(db, "business", id), { 
        lastUpdate : Date.now(),
        status,
      }).then(()=>{
        setselectedBusiness(null)
      }); 
          }

   


    const updateProfile = async () =>{ 
      const obj = {
        lastUpdate : Date.now(),
        account,
        account_status : "APPROVED",
        duration : 365,
        accountUpdatedOn : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`

      }
 
      const accountUpdatedDate =  new Date(obj.accountUpdatedOn);
      accountUpdatedDate.setDate(accountUpdatedDate.getUTCDate() + obj.duration);
      
      await updateDoc(doc(db, "app_users", selectedUser.id), {...obj,  accountUpdatedDate: `${new Date(accountUpdatedDate).getMonth()+1}/${new Date(accountUpdatedDate).getDate()}/${new Date(accountUpdatedDate).getFullYear()}`
          }).then(()=>{
        setselectedUserusiness(null)
        setAccount(null)
      }); 
          }

          const filterByStatus = (status) =>{
            setselectedfilter(status)
            const result = businesses.filter((b)=>b.status === status)
            setfiltered(result)
          }
     
          const filterUsersByStatus = (status) =>{
            setselectedUsersfilter(status) 
            if(status === "PENDING"){
              const result = users.filter((b)=>b.account_status === status)
              setfilteredUsers(result)
              return
            }
            const result = users.filter((b)=>b.account === status)
            setfilteredUsers(result)
          }

          const [value, setValue] = useState(0);
      

          const handleChange = (event, newValue) => {
            setValue(newValue);
          };
       
          const handleAccountChange = (newValue) => {
            setAccount(newValue);
          };




  return (
    user?.admin &&  <>
      <Helmet>
        <title> Admin</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h6" gutterBottom>
            Admin
          </Typography>         
          <Typography variant="body1" gutterBottom>
            Manage business accounts on the platform.
          </Typography>         
        </Stack>
 
 <Grid container>
    <Grid xs={6} md={2}>
         <Typography variant='h3'>
        {businesses?.length}<br/>
        <Typography component={"span"}> Registered Businesses</Typography>
        </Typography>
    </Grid>

    <Grid xs={6} md={2}>
         <Typography variant='h3'>
        {posts?.length}<br/>
        <Typography component={"span"}> Posts Made</Typography>
        </Typography>
    </Grid>
    
    <Grid xs={6} md={2}>
         <Typography variant='h3'>
        {events?.length}<br/>
        <Typography component={"span"}> Events Created</Typography>
        </Typography>
    </Grid>

    <Grid xs={6} md={2}>
         <Typography variant='h3'>
        {albums?.length}<br/>
        <Typography component={"span"}> Albums Created</Typography>
        </Typography>
    </Grid>
 
    <Grid xs={6} md={2}>
         <Typography variant='h3'>
        {users?.length}<br/>
        <Typography component={"span"}> User accounts</Typography>
        </Typography>
    </Grid>
    <Grid xs={6} md={2}>
         <Typography variant='h3'>
        {transactions?.length}<br/>
        <Typography component={"span"}>Transactions</Typography>
        </Typography>
    </Grid>
 </Grid>
<br/>

<Tabs value={value}  variant="scrollable" onChange={handleChange} sx={{mb:4}}>
  <Tab label="Businesses" />
  <Tab label="Users" />
  <Tab label="Transactions" />
  <Tab label="Blogs" />
  <Tab label="Analytics" />
</Tabs>

{value === 0 && <Grid container >
    <Grid item xs={12} md={8}>

    <Grid item xs={12} md={12}>
      <Stack direction="row" spacing={2}>
        <Button color={selectedfilter === "ALL" ? "warning" : "info"} onClick={()=>filterByStatus("ALL")}>All</Button>
        <Button color={selectedfilter === "PENDING" ? "warning" : "info"} onClick={()=>filterByStatus("PENDING")}>Pending</Button>
        <Button color={selectedfilter === "APPROVED" ? "warning" : "info"} onClick={()=>filterByStatus("APPROVED")}>Approved</Button>
      </Stack>
    </Grid>

    {selectedfilter === "ALL" ? businesses?.map((b, index)=>(
            <Card sx={{p:3, mb:2}} key={index}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography>{b.name}
                    <Typography>{b.companyType}<br/>{b.status}</Typography></Typography>
                    
                    <Button color="warning" variant="contained" onClick={()=>setselectedBusiness(b)} >Edit</Button>
                </Stack>

            </Card>
        )) :
        filtered?.map((b, index)=>(
            <Card sx={{p:3, mb:2}} key={index}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography>{b.name}
                    <Typography>{b.companyType}<br/>{b.status}</Typography></Typography>
                    
                    <Button color="warning" variant="contained" onClick={()=>setselectedBusiness(b)} >Edit</Button>
                </Stack>

            </Card>
        ))
         }
    </Grid>
    <Grid item xs={12} md={4} sx={{p:3}}>
        <Typography>Edit Business</Typography>
        <Divider sx={{my:2}} />
        {selectedBusiness && <Stack spacing={2}>
        <Typography variant='h5' fontWeight={"bold"}>{selectedBusiness.name}</Typography>
        <Typography>{selectedBusiness.description}</Typography>
        <Typography>{selectedBusiness.phone}</Typography>
        <Typography>{selectedBusiness.address}</Typography>
        <Typography>{selectedBusiness.category}</Typography>
        <Divider sx={{my:2}} />
        <Typography>{selectedBusiness.province}</Typography>
        <Typography>{selectedBusiness.town}</Typography>
        <Typography>{selectedBusiness.township}</Typography>
        <Divider sx={{my:2}} />
        <Typography>Opening Time : {selectedBusiness.startTime}</Typography>
        <Typography>Closing Time : {selectedBusiness.closeTime}</Typography>
        <Divider sx={{my:2}} />
        <Typography>{selectedBusiness.companyType}</Typography> 
        <Typography>{selectedBusiness.companyType === "COMPANY" && !selectedBusiness.pacra  ? "Missing PACRA data" : 
        selectedBusiness.pacra }</Typography> 
        <Divider sx={{my:2}} >Update company Status</Divider>
        <Typography>{selectedBusiness.status}</Typography> 
        
        <Stack spacing={2}>
        {(selectedBusiness.status === "DECLINED" || selectedBusiness.status === "PENDING" || !selectedBusiness.status ) && <Button color="success" variant="contained" onClick={()=>updateStatus("APPROVED", selectedBusiness.id)} >Approve</Button>}
        {(selectedBusiness.status === "APPROVED" || selectedBusiness.status === "PENDING" || !selectedBusiness.status) && 
    <Button color="error" variant="contained" onClick={()=>updateStatus("DECLINED",selectedBusiness.id)} >Decline</Button>}

        </Stack>
        <Button variant="contained" color="warning" onClick={()=>navigate("/dashboard/editPage", {state : selectedBusiness})}>Edit Business</Button>       
        </Stack>} 
    </Grid>
 </Grid>}
       
       {value === 1 && <Grid container>
        <Grid item xs={12} md={8}>

<Grid item xs={12} md={12}>
  <Stack direction="row" spacing={2}>
    <Button color={selectedUsersfilter === "ALL" ? "warning" : "info"}  onClick={()=>filterUsersByStatus("ALL")}>All</Button>
    <Button color={selectedUsersfilter === "FREE" ? "warning" : "info"} onClick={()=>filterUsersByStatus("FREE")}>FREE</Button>
    <Button color={selectedUsersfilter === "BASIC" ? "warning" : "info"} onClick={()=>filterUsersByStatus("BASIC")}>BASIC</Button>
    <Button color={selectedUsersfilter === "PRO" ? "warning" : "info"} onClick={()=>filterUsersByStatus("PRO")}>PREMIUM</Button>
    <Button color={selectedUsersfilter === "PENDING" ? "warning" : "info"} onClick={()=>filterUsersByStatus("PENDING")}>PENDING</Button>
  </Stack>
</Grid>

{selectedUsersfilter === "ALL" ? users?.map((b, index)=>(
        <Card sx={{p:3, mb:2}} key={index}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>{b.fullName}
                <Typography>{b.account  === "PRO" ? "PREMIUM" : b.account}</Typography></Typography>
                
                <Button color="warning" variant="contained" onClick={()=>setselectedUserusiness(b)} >Edit</Button>
            </Stack>

        </Card>
    )) :
    filteredUsers?.map((b, index)=>(
        <Card sx={{p:3, mb:2}} key={index}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>{b.fullName}
                <Typography>{b.account  === "PRO" ? "PREMIUM" : b.account}</Typography></Typography>
                
                <Button color="warning" variant="contained" onClick={()=>setselectedUserusiness(b)} >Edit</Button>
            </Stack>

        </Card>
    ))
     }
</Grid>
<Grid item xs={12} md={4} sx={{p:3}}>
    <Typography>Edit User Account</Typography>
    <Divider sx={{my:2}} />
    {selectedUser && <Stack spacing={2}>
    <Typography variant='h5' fontWeight={"bold"}>{selectedUser.fullName}</Typography>
    <Typography>{selectedUser.phoneNumber}</Typography>
    <Typography>{selectedUser.email}</Typography>
    <Typography>Account Updated : {selectedUser.accountUpdatedOn}</Typography>
    <Typography>{selectedUser.account === "PRO" ? "PREMIUM" : selectedUser.account }</Typography>
    <Divider sx={{my:2}} >Update Profile account</Divider>

    <TextField fullWidth label="Select account type"   select  value={account}
           onChange={(event) => handleAccountChange(event.target.value)}
          ><MenuItem value={"FREE"}><Typography>FREE</Typography></MenuItem>
          <MenuItem value={"BASIC"}><Typography>BASIC</Typography></MenuItem>
          <MenuItem value={"PRO"}><Typography>PREMIUM</Typography></MenuItem> 
          </TextField>
<Button variant="contained" color="success" onClick={()=>updateProfile()}>Update Account</Button>
    </Stack>} 
</Grid>
       </Grid>}

       {value === 2 && <Transactions data={transactions}/>}
       {value === 3 && <Blogs data={posts}/>}
       {value === 4 && <DashboardAppPage posts={posts} transactions={transactions} users={users} businesses={businesses} />}
      </Container>
    </>
  );
}
