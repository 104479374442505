/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography,  Box, Card, Link, Divider, Button, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Label from '../../../components/label/Label'; 
import AddToAlbum from '../album/AddToAlbum';
import useGetExpired from '../../../hooks/useGetExpired';
 

function ManagePage() {
 
  const  expired = useGetExpired()

  const StyledProductCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  });
      
    const location = useLocation()
    const navigate = useNavigate()
    console.log(location.state)
    const { name, cover_image, phone, category, province, address, id, userId} = location.state;
 
  return (
    <>
    <Helmet>
      <title>View Profile</title>
    </Helmet>

    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
        <Typography component={"span"}>
      Profile
        </Typography><br/>
        {name}
        </Typography>  

         { expired && <Button variant="contained" color="warning" onClick={()=>navigate("/dashboard/editPage", {state : location.state})}>Edit Business</Button>}       
      </Stack>


      <Card>
    <Box sx={{ pt: '25%', position: 'relative' }}>
     
        <Label
          variant="filled"
          color={'warning'}
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: 'absolute',
            textTransform: 'uppercase',
          }}
        >
          {category}
        </Label>
  
      <StyledProductCover alt={name} src={cover_image || "../assets/bck_long.png"} />
    </Box>

    <Stack spacing={2} justifyContent={"space-between"} sx={{ p: 3 }}>
        <Typography variant="subtitle2" noWrap>
        {phone}
        </Typography>
    
            <Typography
            variant="overline"             
          >
      {category}
          </Typography>           
       
          <Typography variant="subtitle2" noWrap>
          {address}
        </Typography>
     </Stack>
     <Divider />

     </Card>
     
    <AddToAlbum id={id}/>
        
    </Container>
  </>
  )
}

export default ManagePage