import { LoadingButton } from '@mui/lab';
import { AppBar, Toolbar, Typography, Button, Stack, TextField, Divider, LinearProgress, CircularProgress, MenuItem, Card } from '@mui/material'
import { addDoc, arrayUnion, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import Dropzone from 'react-dropzone';
import { auth, db } from '../../../firebase';  
import eventTypes from '../../../_mock/events';

// eslint-disable-next-line react/prop-types
function SellItem({handleClose}) {
    const storage = getStorage()    
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);
 
 
 const validationSchema = yup.object().shape({
  category: yup.string().required('category is required'),
  eventTitle: yup.string().required('Event Title is required'),
  eventDescription: yup.string().required('Event Description is required'),
  date: yup.string().required('Date and Time is required'),
  time: yup.string().required('Date and Time is required'),
  location: yup.string().required('Location is required'),   
  generalAdmission: yup.number().required('General Admission is required'),   
});

 
const formik = useFormik({
   initialValues : {
      eventTitle:  "",
      category:   "",
      eventDescription:  "",
      time:  "",
      date:  "",
      location:  "",
      generalAdmission:  "",
      vip: "",
      preorder:  "",
      tags :   "",
      
    },
  validationSchema,
  enableReinitialize: true,
  onSubmit: async (values) => {
      if (images.length === 0 ) {
          alert("select an image to upload")
          return;
      } 
      setloading(true)  
      const docId =  await addDoc(collection(db, 'events'), 
      {...values,  createdAt: Date.now(),
        userId: auth.currentUser.uid})
  
        console.log("Document added with ID: ", docId);
        const storageRef = ref(storage, `events/${docId.id}/`); // Define the storage reference
        // eslint-disable-next-line no-async-promise-executor
         const uploadPromises = images.map((image) => new Promise(async (resolve) => {
             const fileRef = ref(storageRef, image.name);
             const uploadTask = uploadBytesResumable(fileRef, image); 
             uploadTask.on('state_changed',
               (snapshot) => {
                 const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                 setUploadProgress(Math.round(progress)) 
               },
               (error) => {
                 console.error(`Error uploading ${image.name}: ${error.message}`);
                 resolve(null);
               },
               () => {
                 // Upload completed successfully
                 getDownloadURL(fileRef).then(async (downloadURL) => {
                   console.log(`File ${image.name} available at ${downloadURL}`);
                    const userDocRef = doc(db, 'events', docId.id );
                   await updateDoc(userDocRef, {
                     images: arrayUnion(downloadURL),
                   });
                     resolve(downloadURL);
                 });
               }
             );
           }));
       
         try {
           const uploadedImageUrls = await Promise.all(uploadPromises);
           console.log('All images uploaded:', uploadedImageUrls);
           setImages([]); // Clear the uploaded images
           handleClose()
            } catch (error) {
           console.error('Error uploading images:', error);
            
         }
      
 
  },
});
 
 
 
  return (
    auth.currentUser?.uid && <div>
          
  

        <form style={{padding:0}} onSubmit={formik.handleSubmit}>
        <Stack spacing={3} alignItems={"center"} p={3} >
            <Typography>Add Event </Typography>
        <TextField fullWidth select   id="category"
            name="category"
            label="Category"
            value={formik.values.category}
        onChange={formik.handleChange}
        error={formik.touched.category && Boolean(formik.errors.category)}
            helperText={formik.touched.category && formik.errors.category}
        >
      {eventTypes.map((bc, index)=>(
        <MenuItem value={bc} key={index}>{bc}</MenuItem>
      ))}
      </TextField>
          <TextField
            id="eventTitle"
            name="eventTitle"
            label="Event Title"
            fullWidth
            value={formik.values.eventTitle}
            onChange={formik.handleChange}
            error={formik.touched.eventTitle && Boolean(formik.errors.eventTitle)}
            helperText={formik.touched.eventTitle && formik.errors.eventTitle}
          />
          <TextField
            id="eventDescription"
            name="eventDescription"
            label="Event Description"
            fullWidth
            value={formik.values.eventDescription}
            onChange={formik.handleChange}
            error={formik.touched.eventDescription && Boolean(formik.errors.eventDescription)}
            helperText={formik.touched.eventDescription && formik.errors.eventDescription}
          />
          <Stack direction="row"  justifyContent={"space-between"}>

          <TextField
            id="date"
            name="date"
            label="Date"
            fullWidth type='date'
            value={formik.values.date}
            onChange={formik.handleChange}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
          <TextField
            id="time"
            name="time"
            label="Time"
            fullWidth type='time'
            value={formik.values.time}
            onChange={formik.handleChange}
            error={formik.touched.time && Boolean(formik.errors.time)}
            helperText={formik.touched.time && formik.errors.time}
          />
          </Stack>
          <TextField
            id="location"
            name="location"
            label="Location"
            fullWidth
            placeholder='e.g 6501, Lilayi Road, Chalala, Lusaka.'
            value={formik.values.location}
            onChange={formik.handleChange}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />
              <TextField
      id="tags"
      name="tags"
      label="Hashtags (separated by commas)"
      fullWidth
      value={formik.values.tags}
      onChange={formik.handleChange}
      error={formik.touched.tags && Boolean(formik.errors.tags)}
      helperText={formik.touched.tags && formik.errors.tags}
    />
          <TextField
            id="preorder"
            name="preorder"
            label="Pre Order"
            fullWidth type="number"
            value={formik.values.preorder}
            onChange={formik.handleChange}
            error={formik.touched.preorder && Boolean(formik.errors.preorder)}
            helperText={formik.touched.preorder && formik.errors.preorder}
          />
          <TextField
            id="generalAdmission"
            name="generalAdmission"
            label="General Admission"
            fullWidth type="number"
            value={formik.values.generalAdmission}
            onChange={formik.handleChange}
            error={formik.touched.generalAdmission && Boolean(formik.errors.generalAdmission)}
            helperText={formik.touched.generalAdmission && formik.errors.generalAdmission}
          />
          <TextField
            id="vip"
            name="vip"
            label="VIP"
            fullWidth type="number"
            value={formik.values.vip}
            onChange={formik.handleChange}
            error={formik.touched.vip && Boolean(formik.errors.vip)}
            helperText={formik.touched.vip && formik.errors.vip}
          /> 
          <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
           Choose your listing's main photo first. You can add 5 pictures
            </Typography>
            <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                  <Card sx={{p:1, px:3, border:" thin solid #ED6C02"}} 
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} multiple />
                      <p>Click here to select files</p>
                    </Card>
                    <br/>
                  </div>
                )}
              </Dropzone>
              {images.length > 0 && 
        <>
        <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />
    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" color="warning">Create  Event</LoadingButton>
      </>}
         
        </Stack>
      </form>
      
    </div>
  )
}
export default SellItem