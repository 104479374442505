import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Stack, TextField, Divider, CircularProgress, Card, Box } from '@mui/material'
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { auth, db } from '../../../firebase';
import useGetVendorFilesById from '../../../hooks/useGetVendorFilesById';

function UploadPricing({id, pricingGuides}) {
  const files = useGetVendorFilesById(id)
  const navigate = useNavigate()
    console.log(pricingGuides)
    const storage = getStorage()
    const [post, setpost] = useState("")
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);
 
    const handleUpload = () => {
        if (post.trim() === "") {
          alert("Write something to continue");
          return;
        }
        if (images.length > 0) {
          uploadImage(images[0]);
        }  
      }; 
   
        const uploadImage = async (image) => {  
          setloading(true)
          if(image){
            const _id = Date.now();
  
            const fileRef = ref(storage, `businessFiles/${image.name}_${_id}`);
            const uploadTask = uploadBytesResumable(fileRef, image);
          // Listen to state_changed event to track progress
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress)
              setUploadProgress(progress);
            },
            (error) => {
              console.error(`Error uploading ${image.name}: ${error.message}`); 
            },
            () => {
              // Upload completed successfully
              getDownloadURL(fileRef).then(async (downloadURL) => {
                await addDoc(collection(db, "business", id, "files"), {
                  post,
                  createdAt : Date.now(),
                  file : downloadURL,                
                });
      });
      setpost("")
      setImages([])
      setUploadProgress(0)
      setloading(false)
            }
          ); }
      }  
      
      async function  deleteFile(file){
        await deleteDoc(doc(db, 'business', id, "files", file.id)).then(()=>{
          const desertRef = ref(storage, file.file);              
              deleteObject(desertRef).then(() => {
                alert("File deleted successfully.")                
              }).catch((error) => {
                alert("Error, failed to delete the file")
              });
        }) }
 
  return (
    <div>
        <Stack mb={5}>
          <Typography variant="h6" gutterBottom>
            Upload pricing guide
          </Typography>      
           
          {files?.length < pricingGuides && <Card sx={{p:3}}>
    <Box>    
        <Stack      >
        
        <TextField
        fullWidth  
         type="post"
        label="File Title"
        value={post}
        onChange={(event) => setpost(event.target.value)}
      />

  {uploadProgress > 0 && <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
    <Stack direction={"row"} sx={{mt:2}} justifyContent={"space-between"}>
    <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                  <Card sx={{p:1, px:3, border:" thin solid #ED6C02"}} 
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>Click here to select files</p>
                      {images.length === 1 && images[0].name}
                    </Card>
                    </div>
                )}
              </Dropzone>
  
    </Stack><br/>
        </Stack>
        </Box>
      <LoadingButton loading={loading}   variant="contained" onClick={()=>handleUpload()} color="warning">Upload ({pricingGuides - files?.length} left)</LoadingButton>
    </Card>}

            <Card sx={{p:3, }} >
    {files && files.map((f, index)=>(
                <Box key={index} sx={{mb:1}}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>{f.post}</Typography>
                        <div>
                        <Button variant='outlined' color='success' href={f.file} target='_blank' >View</Button>
                        <Button variant='contained' color='error'  onClick={()=>deleteFile(f)} sx={{ml:1}} >Delete</Button>
                        </div>

                    </Stack>
                </Box>
        ))}
            </Card>
        </Stack>
    </div>
  )
}

export default UploadPricing