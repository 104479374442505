import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography, Divider } from '@mui/material';
import {  collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { signInWithPhoneNumber, RecaptchaVerifier, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { LoadingButton } from "@mui/lab";
import { auth, db } from '../../../firebase';
 
// components
import Iconify from '../../../components/iconify';
import GoogleSignIn from '../register/GoogleSignIn';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [phone, setPhone] = useState(""); 
  const [code, setCode] = useState(""); 
  const [verificationId, setVerificationId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
const [loading, setloading] = useState(false)
 
  
  console.log(phone)
  const signInWithPhoneNumberHandler = () => {
    setloading(true)
    const phone_  = `+260${phone}`
    // eslint-disable-next-line radix
    const phoneQuery = query(collection(db, 'app_users'), where('phoneNumber', '==', parseInt(phone)));

    getDocs(phoneQuery)
      .then((querySnapshot) => {
        console.log(querySnapshot.size)
        if (querySnapshot.size === 1) {

          const recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
            'size': 'normal',
            callback: (response) => {
              console.log(response);
              signInWithPhoneNumber(auth, phone_, recaptchaVerifier)
                .then((confirmationResult) => {
                  setloading(false)
                  // SMS sent. Save the verification ID for later use.
                  setVerificationId(confirmationResult.verificationId);
                  console.log(confirmationResult.verificationId);
                })
                .catch((error) => {
                  setloading(false)
                  // Error; SMS not sent
                  console.error(error);
                  setErrorMessage('Error: SMS not sent');
                });
            },
            'expired-callback': () => {
              console.log('reCAPTCHA expired');
            },
          });
         recaptchaVerifier.render()
 
        } else {
          setloading(false)
          navigate('/register', { replace: true }); // Assuming you are using React Router for navigation
        }
      })
      .catch((error) => {
        console.error('Error getting documents:', error);
      });
  };
 
  

  const verifyCode = () => {
    if (code.length !== 6) {
      setErrorMessage('Invalid verification code');
      return;
    }
setloading(true)
    const credential = PhoneAuthProvider.credential(verificationId, code);
    signInWithCredential(auth, credential)
      .then((result) => {
        // User signed in successfully
        const userId = result.user.uid;

        const userRef = doc(db, 'app_users', userId);
        getDoc(userRef)
          .then((doc) => {
            if (doc.exists()) {
              setloading(false)
              navigate("/dashboard")
              // User data exists, do something
            } else {
              // User data does not exist, navigate to registration page
              navigate('/register', { replace: true });
            }
          })
          .catch((error) => {
            setloading(false)
            console.error('Error getting document:', error);
          });
      })
      .catch((error) => {
        setloading(false)
        // Error; Invalid verification code
        console.error(error);
        setErrorMessage('Error: Invalid verification code');
      });
  };

  return (
    <>
        {!verificationId &&  <> <Stack spacing={3}>
 
      <GoogleSignIn />
      <Divider>Use your Phone Number</Divider><br/>
      <Stack direction={"row"} justifyContent={"center"} alignItems="center">
          <Typography variant='overline' style={{fontSize:20, marginRight:10}}>+260</Typography>
          
      <TextField
        fullWidth
      
        type="number"
        label="Phone Number"
        value={phone}
        onChange={(event) => setPhone(event.target.value)}
      />
      </Stack>
      <div id="sign-in-button" style={{width:"100%"}} />
      <LoadingButton fullWidth loading={loading} size="large" color="warning" type="submit" variant="contained" onClick={signInWithPhoneNumberHandler}>
        Login
      </LoadingButton>
      </Stack>

 
      </>
      }

      {verificationId && <>
      <TextField
        fullWidth
        autoComplete="off"
        type="number"
        label="Verification Code"
        value={code}
        onChange={(event) => setCode(event.target.value)}
      />
      <br/>
      <br/>
      <LoadingButton loading={loading} fullWidth variant="contained" color="warning" size="large"onClick={verifyCode}>Verify Code</LoadingButton>
     
      </>}   
 
      <br/>
      {errorMessage && <p>{errorMessage}</p>}
    </>
  );
}
