import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import useGetDirectory from '../hooks/useGetDirectory';
import { ProductSort, ProductList, ProductFilterSidebar } from '../sections/@dashboard/products';
import DirectoryMap from '../sections/@dashboard/products/DirectoryMap';
import Explore from '../sections/@dashboard/products/Explore';


// ----------------------------------------------------------------------

export default function ProductsPage() {  
  const directories = useGetDirectory()?.filter((d)=>d.status === "APPROVED") 
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    directories && <>
      <Helmet>
        <title> Directory </title>
      </Helmet>

      <Container>
  
        <Tabs value={value}  variant="scrollable" onChange={handleChange} sx={{mb:4}}>
  <Tab label="Categories" />
  {/* <Tab label="Map" /> */}
  <Tab label="Explore" />
</Tabs>
        

        {value === 0 && directories && <ProductList products={directories} />}
       {/* {value === 1 &&  directories && <DirectoryMap list={directories} />} */}
       {value === 1 && directories && <Explore list={directories}  /> }
      </Container>
    </>
  );
}
