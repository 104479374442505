// @iconify/react axios date-fns formik yup lodash minisearch react-router-dom uuid
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getDatabase} from 'firebase/database'
import { getAuth, onAuthStateChanged } from "firebase/auth";
 

  const firebaseConfig = {
    apiKey: "AIzaSyBvM5LOA297VSYrLIncF26xYrHIopUpR0Q",
    authDomain: "ilanga-a49f2.firebaseapp.com",
    databaseURL: "https://ilanga-a49f2-default-rtdb.firebaseio.com",
    projectId: "ilanga-a49f2",
    storageBucket: "ilanga-a49f2.appspot.com",
    messagingSenderId: "330194001089",
    appId: "1:330194001089:web:407346b2d868e26bd9c840",
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const realdb = getDatabase(app);

  export  {db, auth, realdb, onAuthStateChanged}
 