import { LoadingButton } from '@mui/lab';
import { AppBar, Toolbar, Typography, Button, Stack, TextField, Divider, CircularProgress, Card, Box } from '@mui/material'
import { addDoc, collection, doc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { auth, db } from '../../../firebase';
import Iconify from '../../../components/iconify';

function CreatePost() {

    const storage = getStorage()
    const [post, setpost] = useState("")
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileType, setFileType] = useState('');
    
      const handleUpload = () => {
        if (post.trim() === "") {
          alert("Write something to continue");
          return;
        }
        if (images.length > 0) {
          const fileType = images[0].type;
          if (fileType.startsWith('image/')){
            setFileType(fileType);
            uploadImage(images[0]);
            return
          }  alert("File type not supported")
          } else {
          // Post without an image
          uploadPost(null);
        }
      };

      const uploadPost = async (imageURL) => {
        setloading(true)
        // Create a new post document
        const postDoc = {
          post,
          createdAt: Date.now(),
          userId: auth.currentUser.uid,
        }; 
        if (imageURL) {
          postDoc.image = imageURL;
        } 
        await addDoc(collection(db, "posts"), postDoc); 
        setloading(false)
        setpost("")
        
      };  
      
      
   
        const uploadImage = async (image) => {  
          setloading(true)
          if(image){
            const id = Date.now();
  
            const fileRef = ref(storage, `posts/${image.name}_${id}`);
            const uploadTask = uploadBytesResumable(fileRef, image);
            uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress)
              setUploadProgress(progress);
            },
            (error) => {
              console.error(`Error uploading ${image.name}: ${error.message}`); 
            },
            () => {
              // Upload completed successfully
              getDownloadURL(fileRef).then(async (downloadURL) => {
                await addDoc(collection(db, "posts"), {
                  post,
                  createdAt : Date.now(),
                  image : downloadURL,
                  fileType,
                  userId : auth.currentUser.uid
                });
      });
      setpost("")
      setImages([])
      setUploadProgress(0)
      setloading(false)
            }
          ); 
        }
      }  
     


  return (
    <Card sx={{p:3}}>
    <Box>    
        <Stack      >
        
        <TextField
        fullWidth  multiline
         type="post"
        label="Share Your Experience..."
        value={post}
        onChange={(event) => setpost(event.target.value)}
      />

  {uploadProgress > 0 && <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
    <Stack direction={"row"} sx={{mt:2}} alignItems={"center"} justifyContent={"space-between"}>
 
    <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                    <div
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} />
                      <Iconify icon="eva:image-fill" width={28}/>
                                     
                    </div>
                    </div>
                )}
              </Dropzone>
  
      <LoadingButton loading={loading}   variant="contained" onClick={()=>handleUpload()} color="success">Send</LoadingButton>
    </Stack>
    <Typography noWrap>{images?.[0]?.name}</Typography>   
        </Stack>
        </Box>
    </Card>
  )
}

export default CreatePost