import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Grid, Card, Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function Free() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
   <>
      <Helmet>
        <title> Analytics</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h6" gutterBottom>
            Overview
          </Typography>         
        </Stack>

        <Typography variant="h6" gutterBottom>
            Last Week in Review
          </Typography> 
        <Typography variant="body1" gutterBottom>
        Take a moment to review activity and insights from last week
          </Typography> 
<br/>
        <Grid container spacing={3}>
            
            <Grid item sx={12} md={6}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Your Goals</Typography> 
            <Typography variant="body1" gutterBottom>Set your goals, track progress and achieve them</Typography> 
            
            <Stack direction="row" justifyContent={"space-between"} >
            <Typography variant="h1" color={"primary"} gutterBottom>12<br/>
            <Typography variant="h6" gutterBottom>Reach</Typography> </Typography>  
            <Typography variant="h1" color={"primary"} gutterBottom>45<br/>
            <Typography variant="h6" gutterBottom>Messages</Typography> </Typography> 
</Stack>
           </Box>
           </Card>  
            </Grid>
         
            <Grid item sx={12} md={6}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Your Activity</Typography> 
            <Typography variant="body1" gutterBottom>You published 49 pieces of content this past week.</Typography> 

            <Typography variant="h1" color={"secondary"} gutterBottom>44<br/>
            <Typography variant="h6" gutterBottom>Posts</Typography> </Typography> 

           </Box>
           </Card>  
            </Grid>

            <Grid item sx={12} md={6}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Your Results</Typography> 
            <Typography variant="body1" gutterBottom>See your overall reach from last week.</Typography> 
            <Typography variant="h1" color={"secondary"} gutterBottom>567<br/>
            <Typography variant="h6" gutterBottom>Reach</Typography> </Typography> 
           </Box>
           </Card> 
            </Grid>

            <Grid item sx={12} md={6}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Your Messaging Activity</Typography> 
            <Typography variant="body1" gutterBottom>Track your progress toward your business messaging goals</Typography>
            <Typography variant="h1" color={"secondary"} gutterBottom>414<br/>
            <Typography variant="h6" gutterBottom>Messages</Typography> </Typography>  
           </Box>
           </Card> 
            </Grid>
        </Grid>
        
      </Container>
    </>
  );
}
