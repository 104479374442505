import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'
import useGetUser from '../hooks/useGetUser'

function UserName({id}) {

    const user = useGetUser(id)
  return (
    user && 
    <Stack direction={"row"} alignItems={"center"}>
    <Avatar src={user.profile_image}  /> 
    <Typography sx={{my:2, ml:2}} variant="overline"> {user.fullName}</Typography>

    </Stack>
  )
}

export default UserName